import React, { useState } from 'react';
import SpeechBubble from '../components/SpeechBubble';
import PresentComponent from '../components/PresentComponent'

function App() {
    const [message, setMessage] = useState('');

    const handlePlayerWin = (playerName) => {
        setMessage(`${playerName} won a prize!`);
        console.log("Won a prize")
    };

    return (
        <div>
            <button onClick={() => handlePlayerWin('John')}>Simulate John Winning</button>
            <SpeechBubble message={message} setMessage={setMessage} />
            <PresentComponent />
        </div>
      
    );
}

export default App;


