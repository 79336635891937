import React from 'react';
import UnwrapPresent from '../components/TestPresent'; // adjust path accordingly

function Daily() {
  return (
    <div className="App">
      <h1>Unwrapping Present Animation</h1>
      <UnwrapPresent />
    </div>
  );
}

export default Daily;



// import { motion } from 'framer-motion';
// import React from 'react';
// import layer1 from './layer1.svg'

// function Daily() {

//   return (
//     <div className="daily-present">
//       <motion.div
//         drag
//       >
//         <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
//           <rect width="100" height="100" fill="red" />
//         </svg>
//       </motion.div>
//     </div>
//   );
// }

// export default Daily;