import { getRequest } from "../utils/services";

const Refresh = () => {


  const response = getRequest(
    `${process.env.REACT_APP_API_URL}/games/refresh`, 
  );
      
  return (
    <div>
      Refreshing server state
    </div>
  );
}

export default Refresh;