import { motion, useAnimation } from "framer-motion";

function PresentComponent() {
  const controls = useAnimation();

  const handleUnwrap = () => {
    controls.start({
      scaleX: 0
    });
  };

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <motion.img
        initial={{ scaleX: 1 }}
        animate={controls}
        transition={{ duration: 0.8 }}
        src="present.jpg"
        alt="Present"
        style={{ width: '300px', height: '300px', display: 'block' }}
      />
      <button onClick={handleUnwrap}>Unwrap</button>
    </div>
  );
}

export default PresentComponent;