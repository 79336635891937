import { createContext, useState, useEffect, useContext, useCallback } from "react"
import io from 'socket.io-client';
import { AuthContext } from "./AuthContext";
import { useNavigate } from 'react-router-dom';

const GameContext = createContext();

// SocketIO
//const process.env.REACT_APP_SOCKET_SERVER_URL = 'http://localhost:4000';
// const process.env.REACT_APP_SOCKET_SERVER_URL = 'http://parcel-test.goodtimes.za.net:82';

const GameContextProvider = ({ children }) => {

  const navigate = useNavigate();
  const { user, renewJwtToken } = useContext(AuthContext)
  const [socket, setSocket] = useState(null);
  const [gamesList, setGamesList ] = useState(null)
  const [gameState, setGameState ] = useState({})
  const [layerReceive, setLayerReceive] = useState(null)
  const [message, setMessage] = useState('');
  const [otherPlayerPrize, setOtherPlayerPrize] = useState(null);
  

  // console.log("GamesList", gamesList)
  // console.log("User", user)
  // console.log("GameState", gameState)

  useEffect(() => {
    if (!user) return
    const newSocket = io(process.env.REACT_APP_SOCKET_SERVER_URL, {
      query: { jwt: user.token } // Send the users token on the inital connection
    });
    setSocket(newSocket);
    
    console.log("GameContext: Creating new socket")
    return () => newSocket.close();
  }, [setSocket, user]);

  useEffect(() => {
    if (!socket) return;
    console.log("GameContext: Socket is now connected!")
    socket.on('gamesList', (list) => {
      setGamesList(list)
    })

    socket.on('connect_error', (error) => {
      if (error.message === 'tokenExpired') {
          console.error("Token has expired. Need to renew.");
          const response = renewJwtToken()
          console.log(response)
          // Take action like refreshing the token or prompting the user
      } else if (error.message === 'jwtError') {
          console.error("There was an error with the JWT.");
          // Handle other JWT-related errors
      } else {
          console.error("Other socket connection error:", error.message);
      }
    });

    // Merge players received from gameState
    function mergePlayers(original, updated) {
      return updated.map((newPlayer, index) => {
        const originalPlayer = original[index];
        // If the original player exists, merge them; otherwise, return the new player
        return originalPlayer ? { ...originalPlayer, ...newPlayer } : newPlayer;
      });
    }

    socket.on('gameState', (state) => {
      console.log("Receiving gameState", state)
      
      setGameState(prevGameState => {
        const previousState = prevGameState[state.id] || {};
    
        // Use our helper function to merge players
        // let players
        // if (state.players) {
        //   players = mergePlayers(previousState.players || [], state.players || []);
        // } else {
        //   players = previousState.players
        // }
    
        return {
          ...prevGameState,
          [state.id]: {
            ...previousState,
            ...state
            // players // override players with our merged version
          }
        };
      });
    });

    socket.on('layerReceive', (data) => {
      setLayerReceive(data)
      console.log("layerReceived", data)
    })

    socket.on('prizeReceive', (message) => {
      console.log("Prize received!!!!!")
      if (message.prize) {
        setMessage(`You have won a ${message.prize}`)

        setGameState(prevGameState => {
          // Set the prizeswon array
          let prizeswon = (prevGameState[message.id] && prevGameState[message.id].prizeswon) || [];
          prizeswon.push({ prize: message.prize, _id: message._id });
          return {
            ...prevGameState,
            [message.id]: {
              ...(prevGameState[message.id] || {}),
              prizeswon: prizeswon
            }
          }
        });
        console.log(message.prize)
      } else {
        setMessage(message.message)
        console.log(message.message)
      }
    })

    socket.on('messagePlayer', (message) => {
        setMessage(message.message)
        console.log(message.message)
    })


    socket.on('otherPlayerWon', (winnings) => {
      setOtherPlayerPrize(winnings);
      console.log("OtherPlayerWon", winnings)
    });

  }, [socket, renewJwtToken])

  const handleJoinServer = async (game) => {
    console.log("This is the handleJoinServer", game)
    //await socket.emit('joinGame', {gameId: gameId})
    //console.log(`Joining game ${gameName} with ID: ${gameId}`);
    //navigate('/game')
    if (game.type === "interval") {
      navigate(`/intgame/${game._id}`);
    } else {
      console.log(`/game/${game._id}`)
      navigate(`/game/${game._id}`);
    }
  };

  // Get list of game servers
  const getListOfServers = useCallback(async() => {
    if (!socket) return
    return await socket.emit('getListOfServers')
  }, [socket])

  // handleJoinGame
  const handleJoinGame = useCallback(async(gameId) => {
    if (!socket) return
    return await socket.emit('joinGame', {gameId: gameId})

  }, [socket])

  const handleUnwrap = async (serverId) => {
    // Do something when "unwrap" is clicked
    console.log('Unwrapping...');
    if (!socket) return;
    //socket.emit('unwrapLayer', {id: gameState.id, current_layer: layerReceive.current_layer, layer_id: layerReceive.layer_id})
    await socket.emit('unwrapLayer', {
      gameId: gameState[serverId].id, 
      current_layer: layerReceive.current_layer, 
      layer_id: layerReceive.layer_id, 
      player: user.name
    })
    // Reset layerReceive to nothing - ie: hide the unwrap button
    setLayerReceive(null)
};

// Handle Unwrap of interval layer
const handleUnwrapInt = async (serverId) => {
  // Do something when "unwrap" is clicked
  console.log('Unwrapping...');
  if (!socket) return;
  //socket.emit('unwrapLayer', {id: gameState.id, current_layer: layerReceive.current_layer, layer_id: layerReceive.layer_id})
  await socket.emit('unwrapLayer', {
    gameId: gameState[serverId].id, 
    player: user.name
  })
  // Reset layerReceive to nothing - ie: hide the unwrap button
  setLayerReceive(null)
};

  return ( 
    <GameContext.Provider value = {{
      socket,
      gamesList,
      getListOfServers,
      handleJoinServer,
      handleJoinGame,
      gameState,
      layerReceive,
      handleUnwrap,
      handleUnwrapInt,
      message,
      otherPlayerPrize,
      setOtherPlayerPrize,
    }}>
      {children}
    </GameContext.Provider>
    )

}

export {
  GameContext,
  GameContextProvider,
};