import { useEffect, useContext } from "react"
import { AuthContext } from "../contexts/AuthContext"
import { useLocation, matchPath } from "react-router-dom"

const VerifyUser = () => {
  const { verifyUser, verifyError } = useContext(AuthContext)

  const location = useLocation();
  const verifyMatch = matchPath('/verifyuser/:token', location.pathname)
  const token = verifyMatch?.params?.token
 
  useEffect(() => {
    console.log("Running useEffect")
    verifyUser({token})
  }, [token, verifyUser])

  return (
    <>
    { verifyError?.error ?
      <div>
        {verifyError.message}
      </div> 
    :
      <div>
        Email address has been verified, please proceed to <a href="/login">login</a>
      </div>
    }
    </>


  )
}

export default VerifyUser;