import React, { useState, useContext, useEffect } from 'react';
import { Container, ListGroup, Row, Col, Card, Button } from 'react-bootstrap';
import { GameContext } from '../contexts/GameContext';

const Lobby = () => {
  const [selectedServer, setSelectedServer] = useState(null);
  const {
    gamesList,
    getListOfServers,
    handleJoinServer
  } = useContext(GameContext)

  useEffect(() => {
    getListOfServers()
  }, [getListOfServers])

  return (
    <Container>
      <Row>
        <div>
          <h3>Join a Game</h3>
        </div>
      </Row>
      <Row>
        <Col md={4}>
          <ListGroup>
            {gamesList && gamesList.map((server) => (
              <ListGroup.Item
                key={server._id}
                onClick={() => setSelectedServer(server)}
                active={selectedServer && selectedServer.id === server.id}
                action
              >
                {server.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        <Col md={8}>
          {selectedServer ? (
            <Card>
              <Card.Header>{selectedServer.name}</Card.Header>
              <Card.Body>

              <Card.Subtitle className="mb-3">Game Status: {selectedServer.state}</Card.Subtitle>

              <Card.Subtitle className="mb-3">Number of Active Players: {selectedServer.active_players}</Card.Subtitle>

              <Card.Subtitle className="mb-3">Minmum number of Players: {selectedServer.min_players}</Card.Subtitle>

                <Card.Subtitle className="mb-3">Prize List:</Card.Subtitle>
                  <ListGroup variant="flush">
                    {selectedServer.prizes.map((prize, index) => {
                        return <ListGroup.Item key={prize._id}>
                          {prize.item}
                          </ListGroup.Item>
                    })}
                  </ListGroup>

                <Button variant="primary" onClick={() => handleJoinServer(selectedServer)}>
                  Join Game
              </Button>

              </Card.Body>
            </Card>
          ) : (
            <p>Select a server to see its details.</p>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Lobby;
