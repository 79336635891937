import PlayerAvatar from '../components/PlayerAvatar'
import { Image } from 'react-bootstrap';

function App() {
  // const [players] = useState([
  //   { id: 1, name: "John", avatar: "john.jpg" },
  //   { id: 2, name: "Jason", avatar: "jason.jpg"}
  //   // ... more players
  // ]);
  const players = [
    { id: 1, name: "Cheese" },
    { id: 2, name: "Jason" },
    { id: 3, name: "Simon" },
    { id: 4, name: "Rose" },
    { id: 5, name: "Jacqui" },
    { id: 6, name: "Jake" },
    { id: 7, name: "Peter" },
    { id: 8, name: "Louise" },
  ]
  // const [currentPlayerId, setCurrentPlayerId] = useState(1);  // set this dynamically
  const totalPlayers = players.length;
  const rotationStep = 360 / totalPlayers;
  const playerSpacing = -175

  // Pass present
  let currentPlayerIndex = 0;
  const playersAvatars = document.querySelectorAll('.avatar'); // All avatars in the circle
  console.log("playersAvatars", playersAvatars)

  function passPresent() {
      currentPlayerIndex++;
      if (currentPlayerIndex >= playersAvatars.length) {
          currentPlayerIndex = 0; // Loop back to the first player if we've gone past the last one
      }
      
      movePresentToPlayer(playersAvatars[currentPlayerIndex]);
  }

  function movePresentToPlayer(player) {
    const present = document.querySelector('.present');
    const playerPosition = player.getBoundingClientRect();
    const circlePosition = document.querySelector('.circle-container').getBoundingClientRect();

    const leftPosition = playerPosition.left - circlePosition.left + (playerPosition.width / 2);
    const topPosition = playerPosition.top - circlePosition.top + (playerPosition.height / 2);

    present.style.transform = `translate(${leftPosition}px, ${topPosition}px)`;
  }
 setInterval(passPresent, 20000);

  return (
    <div className="game-container">
      <div className="circle-container">
        {players.map((player, index) => (
          <div
              key={player.name}
              className={`avatar player${player.id}`}
              style={{
                  transform: `
                      rotate(${rotationStep * index}deg)
                      translateY(${playerSpacing}px)
                      rotate(${-rotationStep * index}deg)
                  `
              }}>
              <PlayerAvatar playerName={player.name} />
          </div>
          ))}
          <div className="text-center mb-4 present">
            <Image src="/present.jpg" alt="Parcel" rounded fluid />
          </div>
      </div>
    </div>
  );
}

export default App;
