import { createContext, useState, useCallback, useEffect } from "react"
import { postRequest, getRequest } from "../utils/services";
import { useNavigate } from "react-router-dom";

// const process.env.REACT_APP_API_URL = "http://localhost:4000/api"
// const process.env.REACT_APP_API_URL = "http://parcel-test.goodtimes.za.net:82/api"

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);
  const [registerInfo, setRegisterInfo] = useState({
    name: "",
    email: "",
    password: ""
  });
  const [loginError, setLoginError] = useState(null);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: ""
  });
  const [verifyError, setVerifyError] = useState(null);

  console.log("UserContext: User", user)
  // console.log("loginInfo", loginInfo)

  useEffect(() => {
    const user = localStorage.getItem("User") // When refreshing the screen we will get user from localStorage
    setUser(JSON.parse(user))
  }, [])

  const updateRegisterInfo = useCallback((info) => {
    setRegisterInfo(info)
  }, []);

  const updateLoginInfo = useCallback((info) => {
    setLoginInfo(info)
  }, []);

  // RegisterUser
  const registerUser = useCallback( async(e) => {
    e.preventDefault()
    setIsRegisterLoading(true)
    setRegisterError(null)

    const response = await postRequest(
      `${process.env.REACT_APP_API_URL}/users/register`, 
      JSON.stringify(registerInfo)
    );

    setIsRegisterLoading(false)

    if(response.error) {
      return setRegisterError(response)
    }

    //localStorage.setItem("User", JSON.stringify(response)) // Store user in local storage, when refreshing user won't have to login again
    //setUser(response)  // Is this correct?  Shouldn't we only setUser on successful login?

  }, [registerInfo]);

  // loginUser
  const loginUser = useCallback(async(e) => {
    e.preventDefault()
    setIsLoginLoading(true)
    setLoginError(null)

    const response = await postRequest(
      `${process.env.REACT_APP_API_URL}/users/login`, 
      JSON.stringify(loginInfo)
    );

    setIsLoginLoading(false)

    if(response.error) {
      return setLoginError(response)
    }

    localStorage.setItem("User", JSON.stringify(response)) // Store user in local storage, when refreshing user won't have to login again
    setUser(response)  // Is this correct?  Shouldn't we only setUser on successful login?
    navigate("/")
  }, [loginInfo, navigate])

  const logoutUser = useCallback(() => {
    localStorage.removeItem("User");
    setUser(null)
  }, [])

  // Renew Token
  const renewJwtToken = useCallback(async() => {

    const response = await postRequest(
      `${process.env.REACT_APP_API_URL}/users/renewjwttoken`, 
      JSON.stringify({})
    );

    if(response.error) {
      return setLoginError(response)
    }

    localStorage.setItem("User", JSON.stringify(response)) // Store user in local storage, when refreshing user won't have to login again
    setUser(response)

  }, []);

  // verifyUser
  const verifyUser = useCallback(async(info) => {
    console.log("Running verifyUser", info.token)
    setVerifyError(null)

    const response = await getRequest(
      `${process.env.REACT_APP_API_URL}/users/verifytoken/${info.token}`, 
    );
      
    if(response.error) {
      return setVerifyError(response)
    }

  }, [])


  return ( 
  <AuthContext.Provider value = {{
    user,
    registerInfo,
    updateRegisterInfo,
    registerUser,
    registerError,
    isRegisterLoading,
    logoutUser,
    loginUser,
    loginError,
    loginInfo,
    updateLoginInfo,
    isLoginLoading,
    verifyUser,
    verifyError,
    renewJwtToken
  }}>
    {children}
  </AuthContext.Provider>
  )
};

export {
  AuthContext,
  AuthContextProvider,
};