import { useEffect, useContext, useState, useRef } from "react";
import { Container, Button, Table, Image, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { GameContext } from "../contexts/GameContext";
import UnwrapPresent from '../components/TestPresent'; // adjust path accordingly

const IntGame = () => {

  const { 
    gameState, 
    layerReceive, 
    handleUnwrap,
    handleUnwrapInt, 
    message, 
    handleJoinGame,
    otherPlayerPrize,
    setOtherPlayerPrize,
  } = useContext(GameContext)

  const { gameId } = useParams();

  // Join the game and receive gameState
  useEffect(() => {
    async function joinGame() {
      const output = await handleJoinGame(gameId)
      console.log("Game: handleJoinGame", output)
    }
    if (!gameState[gameId]) {
      joinGame()
    }
  }, [handleJoinGame, gameId, gameState])

  return (
    <Container className="text-left">
      <Row className="d-flex justify-content-center">
        <Col className="col-md-12 text-center">
          <h1 className="text-center mb-4">{gameState && gameState[gameId] ? gameState[gameId].name : "Joining Game...."}</h1>
          <UnwrapPresent onUnwrap={() => handleUnwrapInt(gameId)} />
        </Col>
      </Row>
    </Container>
  );
}

export default IntGame;