import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from "./contexts/AuthContext"
import { GameContextProvider } from './contexts/GameContext';
import swDev from './swDev';

// ... Rest of the React code
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <GameContextProvider>
        <App />
      </GameContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
);

swDev()