import React, { useState } from 'react';
import { motion } from 'framer-motion';

const UnwrapPresent = ({ onUnwrap }) => {
  const [papersGone, setPapersGone] = useState([false, false, false]);
  const [unwrap, setUnwrap] = useState(false)

  const handleDragEnd = (index) => (event, info) => {
    if (Math.abs(info.offset.x) > 100 || Math.abs(info.offset.y) > 100) {
      setPapersGone(prev => {
        const newStatus = [...prev];
        newStatus[index] = true;
        return newStatus;
      });
      // Call the onUnwrap function passed as prop and only call onUnwrap once, ie: the first tear of the present
      if (onUnwrap && unwrap === false) {
        onUnwrap();
        if (unwrap === false) {
          setUnwrap(true)
        }
      }
    }
  };

  return (
    <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
      {/* Pattern definitions */}
      <defs>
        <pattern id="polkaDots" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="20" height="20" fill="red" />
          <circle cx="10" cy="10" r="5" fill="blue" />
        </pattern>
        <pattern id="stars" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="40" height="40" fill="green" />
          <polygon points="20,1.5 22.5,9 30,9 24,14 27,21.5 20,16.5 13,21.5 16,14 10,9 17.5,9" fill="yellow" />
        </pattern>
      </defs>

      {/* Inside layer (smaller star pattern) */}
      <rect x="10" y="10" width="180" height="180" fill="url(#stars)" />

      {/* Envelope representation */}
      <rect x="50" y="60" width="100" height="70" fill="white" />
      <polygon points="50,60 100,90 150,60" fill="white" />
      {/* Diagonal lines for envelope seams */}
      <line x1="50" y1="60" x2="75" y2="130" stroke="black" strokeWidth="0.5" />
      <line x1="150" y1="60" x2="125" y2="130" stroke="black" strokeWidth="0.5" />

      {/* Outer wrapping paper pieces with straight edges */}
      {!papersGone[0] && (
        <motion.rect
          x="10"
          y="10"
          width="180"
          height="60"
          fill="url(#polkaDots)"
          drag
          onDragEnd={handleDragEnd(0)}
        />
      )}

      {!papersGone[1] && (
        <motion.rect
          x="10"
          y="70"
          width="180"
          height="60"
          fill="url(#polkaDots)"
          drag
          onDragEnd={handleDragEnd(1)}
        />
      )}

      {!papersGone[2] && (
        <motion.rect
          x="10"
          y="130"
          width="180"
          height="70"
          fill="url(#polkaDots)"
          drag
          onDragEnd={handleDragEnd(2)}
        />
      )}
    </svg>
  );
};

export default UnwrapPresent;
