import { Routes, Route, Navigate } from 'react-router-dom'

// Ref howto: https://www.youtube.com/watch?v=w7ejDZ8SWv8&t=5662s
// Ref howto: https://www.youtube.com/watch?v=3VDFjEqemWA&list=PL63c_Ws9ecIRZ6njHRi3cuCkNSfzqyLBn&index=4

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import { Container } from "react-bootstrap";

// pages & components
import Game from "./pages/Game"
import IntGame from "./pages/IntGame"
import Daily from "./pages/Daily"
import Register from "./pages/Register"
import Login from "./pages/Login"
import NavBar from "./components/Navbar"
import VerifyUser from "./pages/VerifyUser"
import Lobby from "./pages/Lobby"
import Bubble from "./pages/Bubble"
import Circle from "./pages/Circle"
import Test from "./pages/Test"
import Refresh from "./pages/Refresh"

// Contexts
import { useContext } from 'react'
import { AuthContext } from './contexts/AuthContext'
import { GameContext } from './contexts/GameContext'

function App() {
  const { user } = useContext(AuthContext)
  const { gameState } = useContext(GameContext)

  return (
    <>
    <NavBar />
    <Container >
      <Routes>
        <Route 
          path="/" 
          element={user ? <Lobby /> : <Login/>} 
        />
        <Route 
          path="/register" 
          element={user ? <Lobby /> : <Register />} 
        />
        <Route 
          path="/login" 
          element={user? <Lobby /> : <Login />} 
        />
        <Route 
          path="/verifyuser/:token" 
          element={<VerifyUser />}
        />
        <Route 
          path="/game/:gameId" 
          element={user && gameState ? <Game /> : <Login />} 
        />
        <Route 
          path="/intgame/:gameId" 
          element={user && gameState ? <IntGame /> : <Login />} 
        />
        <Route
          path="/daily"
          element={user && gameState ?<Daily /> : <Login />}
        />
        {/* This is only temporary to refresh the game state */}
        <Route
          path="/refresh"
          element={user && gameState ?<Refresh /> : <Login />}
        />
        <Route 
          path="/bubble" 
          element={<Bubble /> } 
        />
        <Route 
          path="/circle" 
          element={<Circle /> } 
        />
       <Route 
          path="/test" 
          element={<Test /> } 
        />
        <Route 
          path="*" 
          element={<Navigate to="/" />} 
        />
      </Routes>
    </Container>
    </>

  );
}

export default App;
