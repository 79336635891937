// import React, { useState, } from 'react';
// import { motion, AnimatePresence } from 'framer-motion';


const SpeechBubble = ({ otherPlayerPrize, setOtherPlayerPrize, duration = 3000 }) => {
    // const [isVisible, setIsVisible] = useState(true);
    // console.log("isVisible", isVisible)

    // useEffect(() => {
    //     if (otherPlayerPrize) {
    //         setIsVisible(true);
    //         const timer = setTimeout(() => {
    //             setIsVisible(false);
    //             setOtherPlayerPrize(null);
    //         }, duration);

    //         return () => clearTimeout(timer);
    //     }
    // }, [otherPlayerPrize, duration, setOtherPlayerPrize]);

    return (
        <>
            I won <span>{otherPlayerPrize && otherPlayerPrize.prize}</span>
        </>
        // <AnimatePresence>
        //     {isVisible && (
        //         <motion.div
        //             initial={{ opacity: 0, scale: 0.9 }}
        //             animate={{ opacity: 1, scale: 1 }}
        //             exit={{ opacity: 0, scale: 0.9 }}
        //             transition={{ duration: 0.3 }}
        //             className="speech-bubble"
        //         >
        //             I won <span>{otherPlayerPrize && otherPlayerPrize.prize}</span>
        //         </motion.div>
        //     )}
        // </AnimatePresence>
    );
};

export default SpeechBubble;