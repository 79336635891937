import { useEffect, useContext, useState, useRef } from "react";
import { GameContext } from "../contexts/GameContext";
import { Container, Button, Table, Image, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SpeechBubble from '../components/SpeechBubble';
import PlayerAvatar from '../components/PlayerAvatar'
import { motion } from 'framer-motion';
import { AuthContext } from "../contexts/AuthContext";

const Game = () => {

  const { 
    gameState, 
    layerReceive, 
    handleUnwrap, 
    message, 
    handleJoinGame,
    otherPlayerPrize,
    setOtherPlayerPrize,
  } = useContext(GameContext)

  const { gameId } = useParams();
  const [activePlayers, setActivePlayers ] = useState(0)
  const [rotationStep, setRotationStep] = useState(null)
  const [playerSpacing, setPlayerSpacing] = useState(null)
  // const [playersList, setPlayersList] = useState([])

  const [gameRoomState, setGameRoomState] = useState(null)

  const circleRef = useRef(null);
  const [circleCenterAvatar, setCircleCenterAvatar] = useState({x: 0, y: 0})
  const playersAvatarRefs = useRef([]);
  const [presentPosition, setPresentPosition] = useState({x: 0, y: 0})
  const [presentAnimation, setPresentAnimation] = useState('start')
  const presentRef = useRef(null)

  const [speechBubblePosition, setSpeechBubblePosition] = useState({ x: 0, y: 0 });
  const [speechBubbleAnimation, setSpeechBubbleAnimation] = useState('start')
  const speechBubbleRef = useRef(null)

  // Used to determine the players index in the array
  const { user } = useContext(AuthContext)
  const [playerArrayIndex, setPlayerArrayIndex ] = useState(0) // Find the actual player index in the array as we want this user to be at the bottom of the circle (closest to the user)
  const [rotationalDiff, setRotationalDiff] = useState(0)
  // const rotationalDiff = (rotationStep * playerArrayIndex)

  const presentVariants = {
    start: {
      opacity: 1,
      x: presentPosition.x,
      y: presentPosition.y,
    },
    moveToPlayer: {
      opacity: 1,
      x: presentPosition.x,
      y: presentPosition.y,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  }

  console.log("Game: gameState!!!!", gameState)
  console.log("Game: activePlayers", activePlayers)
  console.log("Game: rotationStep", rotationStep)
  console.log("Game: circleRef", circleRef)
  console.log("Game: circleCenterAvatar", circleCenterAvatar)
  console.log("Game: presentPosition", presentPosition)
  console.log("Game: playerSpacing", playerSpacing)
  // console.log("Game: playersList", playersList)
  console.log("Game: playersAvatarRefs", playersAvatarRefs)
  console.log("Game: playerArrayIndex", playerArrayIndex)
  console.log("Game: rotationalDiff", rotationalDiff)
  console.log("Game: gameRoomState", gameRoomState)
  console.log("Game: speechBubbleAnimation", speechBubbleAnimation)


//   const [presentPosition, setPresentPosition] = useState({ x: 0, y: 0 });
//   const [presentScale, setPresentScale] = useState(1);

const speechBubbleVariants = {
  moveToPlayer: {
    opacity: 0,
    x: speechBubblePosition.x,  // Assuming you have a playerPosition to move the bubble to
    y: speechBubblePosition.y,
  },
  displaySpeechBubble: {
    opacity: [1, 1, 0],
    x: speechBubblePosition.x,  // Assuming you have a playerPosition to move the bubble to
    y: speechBubblePosition.y,
    transition: {
      opacity: {
        duration: 2,  // Total duration for all keyframes
        times: [0, 0.66, 1],  // 2/3 of the time stay fully opaque, then fade out
      },
    }
  },
}

  // Join the game and receive gameState
  useEffect(() => {
    async function joinGame() {
      const output = await handleJoinGame(gameId)
      console.log("Game: handleJoinGame", output)
    }
    if (!gameState[gameId]) {
      joinGame()
    }
  }, [handleJoinGame, gameId, gameState])

  // Set state once gameState has been received
  useEffect(() => {
    if (!gameState[gameId]) return
    const gameRoom = gameState[gameId].gameRooms[0]
    setGameRoomState(gameRoom)

    setActivePlayers(gameRoom.players.length)

    // Find the actual players index in the players array - this can be used to reorder the display of the circle
    console.log("Userid", user)
    const playerIndex = gameRoom.players.findIndex(player => player.id === user._id)
    setPlayerArrayIndex(playerIndex);

    const rstep = 360 / gameRoom.players.length
    setRotationStep(rstep)
    const rdiff = (rotationStep * playerArrayIndex)
    setRotationalDiff(rdiff)

    // Steps to find the center of circle for Avatar, output to be used by CSS transform
    let currentPlayerId = gameRoom.current_player
    console.log("######currentPlayerId", currentPlayerId)
    const circleParams = circleRef.current.getBoundingClientRect()
    let playerParams;
    if (playersAvatarRefs.current[currentPlayerId]) {
      playerParams = playersAvatarRefs.current[currentPlayerId].getBoundingClientRect()
    } else {
      return
    }
    const x = (circleParams.width / 2) - (playerParams.width / 2)
    const y = (circleParams.height / 2) - (playerParams.height / 2)
    setCircleCenterAvatar({x, y })

    // console.log("################### player_x", currentPlayerId, playerParams.x, playerParams.y)

    // Set player radius spacing
    setPlayerSpacing((circleParams.width / 2) + (playerParams.width / 8))

    const presentParams = presentRef.current.getBoundingClientRect()

    console.log("circleParams", circleParams)
    console.log("playerParams", playerParams)
    console.log("presentParams", presentParams)

    // // Move the present to the current player
    const x_adjustment = 95
    let player_x
    let player_y
    // If the player is on the left half of the circle, then put the present on the right of the avatar.  If on the right half then place the present on the left of the avatar
    if ( (playerParams.left - circleParams.left) > x) {
      player_x = playerParams.left - circleParams.left + (playerParams.width / 2) - 35 - x_adjustment ;
      player_y = playerParams.top - circleParams.top  + (playerParams.height / 2);
    } else {
      player_x = playerParams.left - circleParams.left + (playerParams.width / 2) - 35 + x_adjustment ;
      player_y = playerParams.top - circleParams.top  + (playerParams.height / 2);
    }

    setPresentPosition({x: player_x, y: player_y})
    setPresentAnimation('moveToPlayer')

    // Display the SpeechBubble next to the current player
    // const speechBubbleParams = speechBubbleRef.current.getBoundingClientRect()
    // console.log("speechBubbleParams", speechBubbleParams)
    const y_adjustment = 50
    if ( (playerParams.left - circleParams.left) > x) {
      player_x = playerParams.left - circleParams.left;
      player_y = playerParams.top - circleParams.top - playerParams.height - y_adjustment;
    } else {
      player_x = playerParams.left - circleParams.left;
      player_y = playerParams.top - circleParams.top - playerParams.height - y_adjustment;
    }

    setSpeechBubblePosition({x: player_x, y: player_y})
    setSpeechBubbleAnimation('moveToPlayer')
    // setSpeechBubbleAnimation('displaySpeechBubble')
    

  }, [gameState, gameId, playerArrayIndex, rotationStep, user])


  // Display speechBubble if OtherPlayer won a prize
  useEffect(() => {
    if (!otherPlayerPrize) return
    // DisplaySpeech bubble with prize
    setSpeechBubbleAnimation('displaySpeechBubble')
  }, [otherPlayerPrize])

//   // Normal animation when passing between players
//   const passPresentAnimation = useMemo(() => ({
//     x: presentPosition.x,
//     y: presentPosition.y,
//     scale: presentScale,
//     // ...(shouldShake ? shakeAnimation : {}),
//     opacity: gameState && gameState[gameId] && gameState[gameId].current_player == null ? 0 : 1,
//   }),[presentPosition.x, presentPosition.y, presentScale, gameState, gameId])


//   // Start Animation when present is for current player
//   const myPresentAnimationStart = useMemo(() => ({
//     x: presentPosition.x,
//     y: presentPosition.y,
//     scale: 0,
//     transition: { duration: 1, ease: "easeInOut", onComplete: triggerSpecialAnimationEnd }
//   }),[presentPosition.x, presentPosition.y]);
//   const [ presentAnimation, setPresentAnimation ] = useState(passPresentAnimation);

//   function triggerSpecialAnimationEnd() {
//     setPresentAnimation(specialAnimationEnd);
//   }

//   // End Animation when present if for current player
//   const specialAnimationEnd = {
//     x: presentPosition.x,
//     y: presentPosition.y,
//     scale: [0, 3, 2.9, 3.05, 2.95, 3],
//     transition: { duration: 1.5, times: [0, 0.2, 0.5, 0.7, 0.9, 1], ease: "easeInOut" }
//   };

//   // Move present to another player
//   const movePresentToAnotherPlayer = useCallback((playerRef) => {
//     console.log("############# Running movePresentToPlayer")
//     if (!playerRef) return
//     setPresentAnimation(passPresentAnimation)
//     // const present = presentRef.current;
//     const playerAvatarDimensions = playerRef.getBoundingClientRect();
//     console.log("playerAvatarDimensions", playerAvatarDimensions)
//     const circlePosition = circleRef.current.getBoundingClientRect();
//     console.log("circlePosition", circlePosition)

//     let x_presentDiff = 0;

//     if (first180) {
//       x_presentDiff = -180
//     }

//     console.log("x_presentDiff", x_presentDiff)
//     const x = playerAvatarDimensions.left - circlePosition.left  + (playerAvatarDimensions.width) + x_presentDiff;
//     const y = playerAvatarDimensions.top - circlePosition.top  + (playerAvatarDimensions.height / 2);
//     console.log("setPresentPosition", x, y)
//     setPresentPosition({ x, y });
//   }, [passPresentAnimation, setFirst180])

  // Process layerReceived and move parcel depending on if its for the current player or another player
  // useEffect(() => {
  //   if (!gameState[gameId]) return
  //   console.log("GameState players", gameState[gameId].players)
  //   // print out the current player who has the present!!
  //   setTimeout(() => { // This is a bit of hack, its here so that when initially loading the page, the players are set in position and then this renders.  It's to get the correct position of the player
  //     let currentPlayerId = gameState[gameId].current_player
  //     let currentPlayerRef = playersAvatarRefs.current[currentPlayerId]
  //     // Check if player is in 1st or 2nd 180 degrees of circle (will be used to display present / speech bubble on the correct side of the player)
  //     let playerDegrees = 0;
  //     if (playerArrayIndex > currentPlayerId) {
  //       playerDegrees = 180 - (rotationStep * (playerArrayIndex + currentPlayerId))
  //     } else {
  //       playerDegrees = (rotationStep * (playerArrayIndex + currentPlayerId)) - 180
  //     }

  //     if (playerDegrees > 0 && playerDegrees <= 180) {
  //       setFirst180(true)
  //     } else {
  //       setFirst180(false)
  //     }

  //     console.log("playerDegrees", playerDegrees)
  //     // console.log("currentPlayerRef!!!!", currentPlayerRef)
  //     console.log("currentPlayerId", currentPlayerId)
  //     if (layerReceive) {
  //       console.log("############# Received layer")
  //       // Now pass to the center of the circle and increase the size of the present and make it shake / special effects
  //       // Move to the center of the circle
  //       const circlePosition = circleRef.current.getBoundingClientRect();
  //       // console.log("circlePosition", circlePosition)
  //       const x = circlePosition.width / 2;
  //       const y = circlePosition.height / 2;
  //       setPresentPosition({ x, y });
  //       // console.log("PresentPosition", presentPosition)
  //       setPresentScale(3)
  //       setPresentAnimation(myPresentAnimationStart)
  //     } else {
  //       console.log("Other player has the layer", currentPlayerId)
  //       setPresentScale(1)
  //       movePresentToAnotherPlayer(currentPlayerRef)
  //     }
  //   }, 0)
  // }, [layerReceive, gameState, gameId, movePresentToAnotherPlayer, myPresentAnimationStart])


//   // Move the speech bubble to the winning player
//   useEffect(() => {
//     if (!otherPlayerPrize) return
//     // Move the speech bubble to the winning player
//     let winningPlayerId = otherPlayerPrize.playerId
//     let winningPlayeRef = playersAvatarRefs.current[winningPlayerId]
//     console.log("winningPlayeRef", winningPlayeRef)
//     moveSpeechBubbleToPlayer(winningPlayeRef)
//   }, [otherPlayerPrize])

//   function moveSpeechBubbleToPlayer(winningPlayeRef) {
//         // Move the speechBubble
//         // const speechBubble = speechBubbleRef.current;
//         const playerAvatarDimensions = winningPlayeRef.getBoundingClientRect();
//         const circlePosition = circleRef.current.getBoundingClientRect();

//         let x_presentDiff = 0;

//         if (first180) {
//           x_presentDiff = -220
//         }

//         const x = playerAvatarDimensions.left - circlePosition.left  + (playerAvatarDimensions.width / 2);
//         console.log("x", x)
//         const y = playerAvatarDimensions.top - circlePosition.top - 180 + (playerAvatarDimensions.height / 2);
//         setSpeechBubblePosition({ x, y });
//         // speechBubble.style.transform = `translate(${sbleftPosition}px, ${sbtopPosition}px)`;
//   }

// // Set players speech bubble to visible, if they won a prize
// const [isVisible, setIsVisible] = useState(null);
// useEffect(() => {
//   if (otherPlayerPrize) {
//       setIsVisible(otherPlayerPrize.playerId);
//       const timer = setTimeout(() => {
//           setIsVisible(null);
//           setOtherPlayerPrize(null);
//       }, 3000);

//       return () => clearTimeout(timer);
//   }
// }, [otherPlayerPrize, setOtherPlayerPrize]);


  return (
    <Container className="text-left">
      <Row>
        <Col className="col-md-12">
        <h1 className="text-center mb-4">{gameState && gameState[gameId] ? gameState[gameId].name : "Joining Game...."}</h1>
        
        <div ref={circleRef} className="circle-container">
            <div className="eye"></div>

          {gameRoomState && gameRoomState.players ? gameRoomState.players.map((player, index) => (
              <div
                key={index}
                className={`avatar text-center player${index}`}
                ref={el => playersAvatarRefs.current[index] = el}
                style={{
                    transform: `
                        translateY(${circleCenterAvatar.y}px)
                        translateX(${circleCenterAvatar.x}px)
                        rotate(${(rotationStep) * index - rotationalDiff}deg)
                        translateY(${playerSpacing}px)
                        rotate(${(-rotationStep) * index + rotationalDiff}deg)
                    `
                }}>
                <PlayerAvatar playerName={player.name} />
                <span>{player.name}</span>
              </div>
            )) : null}

          <motion.div
              ref={presentRef}
              variants={presentVariants}
              initial="start"
              animate={presentAnimation}
              className="text-center mb-4 present"
          >
              <Image src="/present.jpg" alt="Parcel" rounded fluid />
          </motion.div>

          <motion.div
              ref={speechBubbleRef}
              variants={speechBubbleVariants}
              initial="moveToPlayer"
              animate={speechBubbleAnimation}
              className="text-center mb-4 speech-bubble"
          >
          <SpeechBubble otherPlayerPrize={otherPlayerPrize} setOtherPlayerPrize={setOtherPlayerPrize} />
         </motion.div>

        </div>
        </Col>
      </Row>


        {/* Status updates*/}
        <div className="text-center mb-4 pt-5">
          <h3>{message ? message : "Waiting for game to start"} </h3>
        </div >
        

        
        {/* Unwrap Button */}
        <div className="text-center mb-4">
        {layerReceive !== null ? <Button variant="primary" onClick={() => handleUnwrap(gameId)}>Unwrap</Button> : <Button disabled>Unwrap</Button> }
        </div>
        
        {/* Game Status Table */}
        <Table bordered hover>
          <tbody>
            <tr>
              <td>Who has the parcel?</td>
              <td>{gameRoomState && gameRoomState.current_player !== null ? gameRoomState.players[gameRoomState.current_player].name : "Waiting for game to start"}</td>
            </tr>
            <tr>
              <td>Number of players:</td>
              <td>{gameRoomState && gameRoomState.players && gameRoomState.players.length}</td>
            </tr>
            <tr>
              <td>Prizes won:</td>
              <td>
                <ul>
                  {gameState && gameState[gameId] && gameState[gameId].prizeswon ? gameState[gameId].prizeswon.map((prize, idx)  => (
                    <li key={idx}>{prize.prize}</li>
                  )) : null }
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
        
      </Container>

  );
}
 
export default Game;