import { postRequest } from "./utils/services";

export default function swDev() {

  function determineAppServerKey() {
    var vapidPublicKey = 'BPnQ7bFpVfkZrOMIFK7HBRXSBL2CCDuWLvanHdkqfaN0y5XcRLBfUaHDwQCKEbQLcg7ty6-p8p0VbBsjnP3M-fM';
    return urlBase64ToUint8Array(vapidPublicKey);
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  if ('serviceWorker' in navigator) {
    let swURL = `${process.env.PUBLIC_URL}/sw.js`;
    
    navigator.serviceWorker.register(swURL).then(registration => {
      console.log("Service Worker registered successfully:", registration);
      
      registration.pushManager.getSubscription().then(subscription => {
        if (subscription) {
          console.log("User is already subscribed:", subscription);
          return;
        }

        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: determineAppServerKey()
        }).then(newSubscription => {
          console.log("User subscribed:", newSubscription);

          // Subscribe to backend - register subscription with the user
          postRequest(
            `${process.env.PUBLIC_URL}/api/users/subscribe`, 
            JSON.stringify(newSubscription)
          ).then(response => {
            if (!response.ok) {
              throw new Error('Bad status code from server.');
            }
            return response.json();
          }).then(responseData => {
            if (!(responseData.data && responseData.data.success)) {
              throw new Error('Bad response from server.');
            }
          }).catch(error => {
            console.error('Failed to subscribe the user on server:', error);
          });

        }).catch(error => {
          console.error("Failed to subscribe the user:", error);
        });

      }).catch(error => {
        console.error("Error getting subscription:", error);
      });

    }).catch(error => {
      console.error("Service Worker registration failed:", error);
    });
  }
}
