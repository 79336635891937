import React from 'react';
import multiavatar from '@multiavatar/multiavatar/esm'

function PlayerAvatar({ playerName }) {
  const avatarSVG = multiavatar(playerName);

  return (
    <div dangerouslySetInnerHTML={{ __html: avatarSVG }} />
  );
}

export default PlayerAvatar;