import { Container, Row, Col } from 'react-bootstrap';

const Test = () => {

  return (
    <>
    <Row>
      <Col className="col-xs-col-sm-1 col-md-9 bg-col-1 text-center">
        <span>Heading 1</span>
      </Col>
      {/* <Col className="col-sm-2 col-md-2 bg-col-2">
      <span>Heading 2</span>
      </Col>
      <Col className="col-sm-9 col-md-1 bg-col-3">
      <span>Heading 3</span>
      </Col> */}
    </Row>
    <Row>
      <div className="my-circle-test">
          
      </div>
    </Row>
    </>
  )
}
export default Test