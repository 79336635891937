export const postRequest = async(url, body) => {

  // Get the JWT token from localStorage
  const userObjectString = localStorage.getItem("User");
  const userObject = JSON.parse(userObjectString);
  const token = userObject ? userObject.token : null;
  console.log("This is my token", token)

  // Set default headers
  const headers = {
    "Content-Type": "application/json"
  };

  // Conditionally add the Authorization header if the token exists
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: body
  });
  const data = await response.json()

  if (!response.ok) {
    let message;

    if (data?.message) {
      message = data.message
    } else {
      message = data
    }
    return {error: true, message: message}
  }

  return data
}

export const getRequest = async(url, body) => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    body: body
  });
  const data = await response.json()

  if (!response.ok) {
    let message;

    if (data?.message) {
      message = data.message
    } else {
      message = data
    }
    return {error: true, message: message}
  }

  return data
}